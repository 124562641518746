import React, { ReactElement, ReactNode, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import Header from '@components/Header';
import Footer from '@components/Footer';
import Profile from '@components/Profile';
import Modals from '@components/modals';
import { RouteNames, RouteParameters } from '@rambler-help/shared';
import { EItemType } from '@rambler-help/shared';
import { Menu, MenuConfig } from '@rambler-help/components';
import { buildMenuConfig } from '@rambler-help/helpers';
import { useRootStore } from '@stores/index';
import { EPageType } from '@rambler-help/shared';
import YandexMetrika from '@components/trackers/YandexMetrika';
import Top100 from '@components/trackers/Top100';
import LiveInternet from '@components/trackers/LiveInternet';
import { YANDEX_METRIKA_IDS, TOP_100_ID, TOP_100_PORTAL_ID } from '@constants/trackers';
import DefaultMeta from '../DefaultMeta';
import { ReactComponent as SupportIcon } from './images/write.svg';

// import '@rambler-help/assets/styles/fonts.css';
// import '@rambler-help/assets/styles/layout.css';
import '@app/assets/styles/fonts.css';
import '@app/assets/styles/layout.css';

import { css } from '@linaria/core';
import { styled } from '@linaria/react';

const Layout = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
  box-sizing: border-box;
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
  color: #343b4c;
`;
const PageBody = styled.div<{ withMenu?: boolean }>`
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  width: ${({ withMenu }) => (withMenu ? '1260px' : '940px')};
  margin: 0 auto 20px auto;
  padding-top: 20px;
`;
const MenuContent = styled.div`
  margin-right: 20px;
`;
const PageContent = styled.div`
  width: 100%;
`;
const supportIconStyle = css`
  display: inline-block;
  vertical-align: bottom;
  width: 20px;
  height: 20px;
  margin-right: 10px;
`;

interface DefaultLayoutProps {
  children?: ReactNode;
}

const DefaultLayout = observer(({ children }: DefaultLayoutProps): ReactElement => {
  const { layout, projects, sections, articles, feedbackForms } = useRootStore();
  const { pathname } = useLocation();
  let isMenuVisible = (
    layout?.pageType === EPageType.Project ||
    layout?.pageType === EPageType.Article ||
    layout?.pageType === EPageType.Feedback ||
    layout?.pageType === EPageType.Search
  );
  // @TODO: Вынести главный правовой документ (ссылка в футере) в отдельный раздел и удалить эту проверку.
  if (! projects.project || projects.project.id === 69) {
    isMenuVisible = false;
  }
  const menuConfig: MenuConfig = useMemo(() => {
    let menuConfig: MenuConfig = [];
    if (isMenuVisible) {
      menuConfig.push({
        id: RouteNames.USER_PROJECT_PAGE.replace(':projectSlug', projects.project.slug),
        type: EItemType.ITEM,
        title: `О проекте «${projects.project.title}»`,
        link: RouteNames.USER_PROJECT_PAGE.replace(':projectSlug', projects.project.slug),
      });
      if (sections.sections) {
        menuConfig = menuConfig.concat([
          ...(sections.sections.map(
            section => (buildMenuConfig(projects?.project?.slug || '', section))
          ) || []),
        ]);
      }
      if (articles.common?.length) {
        menuConfig.push({
          id: 'common',
          type: EItemType.GROUP,
          title: 'Общие вопросы',
          link: '',
          content: articles.common.map(article => ({
            id: article.id,
            title: article.title,
            link: RouteNames.USER_COMMON_ARTICLE_PAGE
              .replace(RouteParameters.ARTICLE_ID, article.id.toString())
              .replace(RouteParameters.PROJECT_SLUG, projects.project.slug),
          })),
        });
      }
      if (articles.legal?.length) {
        menuConfig.push({
          id: 'legal',
          type: EItemType.GROUP,
          title: 'Правила пользования и конфиденциальность',
          link: '',
          content: articles.legal.map(article => ({
            id: article.id,
            title: article.title,
            link: RouteNames.USER_LEGAL_ARTICLE_PAGE
              .replace(RouteParameters.ARTICLE_ID, article.id.toString())
              .replace(RouteParameters.PROJECT_SLUG, projects.project.slug),
          })),
        });
      }
      if (feedbackForms.forms?.length) {
        menuConfig.push({
          id: RouteNames.USER_FEEDBACK_FORM_PAGE.replace(':projectSlug', projects.project.slug),
          type: EItemType.ITEM,
          title: 'Написать в поддержку',
          icon: <SupportIcon className={supportIconStyle} />,
          link: RouteNames.USER_FEEDBACK_FORM_PAGE.replace(':projectSlug', projects.project.slug),
        });
      }
    }
    return menuConfig;
  }, [
    isMenuVisible,
    projects.project, sections.sections, articles.common, articles.legal, feedbackForms.forms
  ]);
  const aboutProjectPage = (
    projects.project &&
    pathname === RouteNames.USER_PROJECT_PAGE.replace(':projectSlug', projects.project.slug)
  );
  const activeSection = (
    articles.activeCommonArticle ||
    articles.activeLegalArticle
  )
    ? (articles.activeCommonArticle ? 'common' : 'legal')
    : aboutProjectPage
      ? RouteNames.USER_PROJECT_PAGE.replace(':projectSlug', projects.project.slug)
      : sections.activeSection?.id;
  const activeSubsection =
    (articles.activeCommonArticle || articles.activeLegalArticle)
    ? (articles.activeCommonArticle?.id || articles.activeLegalArticle?.id)
    : sections.activeSubsection?.id;
  return (
    <React.Fragment>
      <DefaultMeta />
      <Layout>
        <Header />
        <PageBody withMenu={isMenuVisible}>
          {isMenuVisible && (
            <MenuContent id="Menu">
              <Profile />
              <Menu
                config={menuConfig}
                activeSection={activeSection}
                activeSubsection={activeSubsection}
              />
            </MenuContent>
          )}
          <PageContent>
            {children}
          </PageContent>
        </PageBody>
        <Footer />
        <Modals />
      </Layout>
      <YandexMetrika ids={YANDEX_METRIKA_IDS} />
      <Top100 id={TOP_100_ID} />
      <Top100 id={TOP_100_PORTAL_ID} />
      <LiveInternet />
    </React.Fragment>
  );
});

export default DefaultLayout;
