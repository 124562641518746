import { runInAction, makeAutoObservable } from 'mobx';

import {
  TProjectMainRead,
  findProjectById,
  findProjectsMain
} from '@rambler-help/prisma';
import {
  TProjectRead,
  findProjectBySlug,
  findProjectsByIds
} from '@rambler-help/prisma';
import { logError } from '@rambler-help/stores';

export default class ProjectsStore {

  constructor(initialData?: ProjectsStore) {
    if (initialData) {
      Object.assign(this, initialData);
    }
    makeAutoObservable(this);
  }

  project: TProjectRead | undefined;
  projects: Array<TProjectRead> | undefined;
  projectsMain: Array<TProjectMainRead> | undefined;

  load = async (): Promise<Array<TProjectRead> | null> => {
    try {
      const projectsMain = await findProjectsMain({
        orderBy: {
          position: 'asc',
        },
      });
      if (projectsMain) {
        runInAction(() => {
          this.projectsMain = projectsMain;
        });
      }
      const projectsMainIds = projectsMain?.map(projectMain => projectMain.projectId) || [];
      const projects = await findProjectsByIds({
        ids: projectsMainIds,
        orderBy: {
          main: {
            position: 'asc',
          }
        }
      });
      if (projects) {
        runInAction(() => {
          this.projects = projects;
        });
        return projects;
      } else {
        return null;
      }
    } catch(error) {
      logError(error);
    }
    return null;
  };

  loadById = async (id: number): Promise<TProjectRead | null> => {
    try {
      const project = await findProjectById({
        id,
      });
      if (project) {
        runInAction(() => {
          this.project = project;
        });
        return project;
      } else {
        return null;
      }
    } catch(error) {
      logError(error);
    }
    return null;
  };

  loadBySlug = async (projectSlug: string): Promise<TProjectRead | null> => {
    try {
      const project = await findProjectBySlug({
        slug: projectSlug,
      });
      if (project) {
        runInAction(() => {
          this.project = project;
        });
        return project;
      } else {
        return null;
      }
    } catch(error) {
      logError(error);
    }
    return null;
  };

}
