import React, { ReactElement } from 'react';

import { useRootStore } from '@stores/index';

const LiveInternet = (): ReactElement => {
  const { layout } = useRootStore();
  return (
    <React.Fragment>
      <script
        nonce={layout.cspNonce}
        dangerouslySetInnerHTML={{ __html: `
          new Image().src = "//counter.yadro.ru/hit;rambler?r"+
          escape(document.referrer)+((typeof(screen)=="undefined")?"":
          ";s"+screen.width+"*"+screen.height+"*"+(screen.colorDepth?
          screen.colorDepth:screen.pixelDepth))+";u"+escape(document.URL)+
          ";h"+escape(document.title.substring(0,150))+
          ";"+Math.random();
        `}}
      />
    </React.Fragment>
  );
}

export default LiveInternet;
